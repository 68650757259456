import { PaymentMethod } from '../types/paymentCondition';

const PAYMENT_METHOD_MAP: Record<PaymentMethod, string> = {
    [PaymentMethod.CREDIT_CARD]: 'Cartão de crédito',
    [PaymentMethod.PIX]: 'Pix',
};

export const paymentMethodMap = (method: PaymentMethod) => {
    const result = PAYMENT_METHOD_MAP[method];

    if (!result) throw new Error(`Invalid payment method ${method}`);

    return result;
};
