import { config } from 'contants/config';
import GoogleAnalytics from './GoogleAnalytics';
import SanarAnalytics from './SanarAnalytics';
import { Monitoring } from '@sanardigital/monitoring-wrapper';

export const initializeTrackers = (environment?: string) => {
    /**
     * React Google Analytics 4 documentation
     * {@link https://github.com/codler/react-ga4}
     */
    GoogleAnalytics.init({
        key: config.GA_MEASUREMENT_ID,
        options: {},
    });

    Monitoring.init(`sanarflix-checkout-frontend-${environment}`);
    SanarAnalytics.init();
};
