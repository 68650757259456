import env from '@beam-australia/react-env';
import { FLXSnackbarProvider } from 'components/Snackbar';
import { PageProvider } from 'contexts/page-context';
import { DefaultSeo } from 'next-seo';
import SEO from 'next-seo.config';
import Head from 'next/head';
import { useEffect } from 'react';
import { initializeSentry } from 'services/lambda/log-event';
import { ThemeProvider } from 'styled-components';
import theme from 'theme';
import { initializeTrackers } from '../Config/Trackers';
import GoogleAnalytics from '../Config/Trackers/GoogleAnalytics';
import SanarAnalytics from 'Config/Trackers/SanarAnalytics';
import { FLXMetrics } from '../hooks/metrics';
import '../utils/types/constants';
import { UnleashProvider } from 'hooks/unleash';
import ModalProvider from '../contexts/modal-context';

export default function MyApp({ Component, pageProps, err }) {
    useEffect(() => {
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            const f = d.getElementsByTagName(s)[0],
                j: any = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-MPW3TWB');
    }, []);

    useEffect(() => {
        initializeTrackers(env('ENVIRONMENT'));
    }, []);

    initializeSentry();

    const enableHubspot =
        env('ENVIRONMENT') === 'prod' || env('ENABLE_HUBSPOT') === 'true';

    return (
        <FLXMetrics trackers={[SanarAnalytics, GoogleAnalytics]}>
            <DefaultSeo {...SEO} />
            <Head>
                <script src='/checkout/__ENV.js' />
                <meta
                    name='viewport'
                    content='width=device-width, initial-scale=1'
                />
                <meta name='theme-color' content='#B21212' />
                <meta
                    name='apple-mobile-web-app-status-bar-style'
                    content='#B21212'
                />
                <meta name='msapplication-navbutton-color' content='#B21212' />
                <meta httpEquiv='cache-control' content='public' />
                <meta httpEquiv='Cache-Control' content='max-age=31536000' />
                <meta charSet='utf-8' />

                <script
                    type='text/javascript'
                    src='https://code.jquery.com/jquery-3.5.1.slim.min.js'
                />

                <script
                    key='new-relic-script'
                    type='text/javascript'
                    src={`/checkout/js/new-relic-${env('ENVIRONMENT')}.js`}
                />
                {enableHubspot && (
                    <script
                        type='text/javascript'
                        id='hs-script-loader'
                        async
                        defer
                        src={`//js.hs-scripts.com/${env(
                            'HUBSPOT_PORTAL_ID'
                        )}.js`}
                    />
                )}
            </Head>
            <UnleashProvider>
                <ThemeProvider theme={theme}>
                    <ModalProvider>
                        <PageProvider>
                            <FLXSnackbarProvider>
                                <Component {...pageProps} err={err} />
                            </FLXSnackbarProvider>
                        </PageProvider>
                    </ModalProvider>
                </ThemeProvider>
            </UnleashProvider>
        </FLXMetrics>
    );
}
