import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { ParsedUrlQueryInput } from 'querystring';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

import pushEvent from 'Config/Trackers/datalayer';
import { Loading } from 'components/Loading';
import { addsUserContext } from 'services/lambda/log-event';
import {
    sendNewPayment,
    verifyEmailNewPayment,
} from 'services/lambda/new-payment';

import { useMetricsContext } from 'hooks/metrics';
import { usePageContext } from 'contexts/page-context';

import {
    HAS_ACTIVE_SUBCRIPTION,
    HAS_INACTIVE_SUBSCRIPTIONS,
    NEW_PAYMENTS_ENTITY_ERROR,
    PURCHASE_REJECTED,
} from 'contants/errors';

import { breakpoint } from 'contants/breakpoint';

import logErrorOnNewRelic from 'utils/newRelic/logErrorOnNewRelic';

import { User } from 'utils/types/user';
import { Field } from 'utils/types/field';
import { Infos } from 'utils/types/infos';
import { PaymentInfo } from 'utils/types/paymentInfo';
import { useMediaQuery } from '@material-ui/core';
import { InvalidPlan } from 'components/InvalidPlan';

import env from '@beam-australia/react-env';
import LocalStorageHelper from 'utils/common/storage';
import useHubspot from '../hooks/hubspot/useHubspot';
import { PaymentMethod } from '../utils/types/paymentCondition';
import { isCurrentSemester } from '../utils/common/getYears';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { Feature } from 'hooks/unleash';
import { paymentMethodMap } from '../utils/format/payment-method';

const Desktop = dynamic(() => import('components/Pages/desktop'), {
    loading: () => <Loading />,
});

const MobileWithoutPersonalDataForm = dynamic(
    () => import('components/Pages/mobile'),
    {
        loading: () => <Loading />,
    }
);

const MobileWithPersonalDataForm = dynamic(
    () => import('components/Pages/mobileWithPersonalDataForm'),
    { loading: () => <Loading /> }
);

const skipAccessCheck = env('SKIP_ACCESS_CHECK') === 'true';

export type Props = {
    email?: string;
    errorCode?: number;
    onVerifyEmail: (
        values: Field,
        setUser: React.Dispatch<React.SetStateAction<User>>
    ) => Promise<number>;
    onSendPayment: (paymentInfo: PaymentInfo, user: any) => Promise<any>;
    setErrorCode: React.Dispatch<React.SetStateAction<number>>;
    setFields: React.Dispatch<React.SetStateAction<Field>>;
    fields: Field;
    query?: ParsedUrlQueryInput;
} & Infos;

export default function Checkout() {
    const { query, push } = useRouter();
    const { sendMetric, identify } = useMetricsContext();
    const flagsReady = useFlagsStatus();
    const disableMobileForm = useFlag(Feature.DISABLE_MOBILE_FORM);

    const [width, setWidth] = useState<number>();
    const [errorCode, setErrorCode] = useState();
    const { sendPersonalForm, sendPaymentForm } = useHubspot();

    const Mobile = useMemo(() => {
        return disableMobileForm
            ? MobileWithPersonalDataForm
            : MobileWithoutPersonalDataForm;
    }, [disableMobileForm, flagsReady]);

    const {
        fields,
        setFields,
        infos,
        email,
        setEmail,
        order,
        setErrorMessage,
        updateOrder,
        setEcommerceCoupon,
        resetCoupon,
        onTrack,
    } = usePageContext();

    const isWeb = useMediaQuery(`(min-width:${breakpoint}px)`);

    const onVerifyError = (err) => {
        logErrorOnNewRelic(err, {
            error: 'Error on verifyEmailNewPayment',
        });
        setErrorMessage(err.message || err);
        setErrorCode(err);
        push('/falha');
        throw err;
    };

    const onSendSuccess = () => {
        pushEvent(order, 'purchase', fields.installments);
        order?.couponCode
            ? onTrack('COUPON_PURCHASE')
            : onTrack('PURCHASE_COMPLETED');
        setErrorCode(null);
        setErrorMessage(null);
        setFields(null);

        if (order?.payment?.paymentMethod !== PaymentMethod.PIX) {
            push({
                pathname: '/obrigadoMedicina',
                query: {
                    plan: order.planType,
                    ...query,
                },
            });
        }
    };

    const onSendError = (err) => {
        logErrorOnNewRelic(err, {
            error: 'Error on onSendPayment',
        });

        resetCoupon();
        setErrorMessage(err.message || err);
        if (err === PURCHASE_REJECTED.code) {
            push({
                pathname: '/rejeitado',
                query: {
                    ...query,
                },
            });
        } else if (err !== PURCHASE_REJECTED.code) {
            push({
                pathname: '/falha/pagamento',
                query: {
                    paymentError: err === NEW_PAYMENTS_ENTITY_ERROR.code,
                    ...query,
                },
            });
        }

        throw err;
    };

    const onVerifyEmail = useCallback(
        (
            values: Field,
            setUser: React.Dispatch<React.SetStateAction<User>>
        ) => {
            pushEvent(order, 'checkout_confirmed_data');
            sendMetric({
                eventName: 'Confirm data: clicked',
                eventProperties: {
                    plan_slug: order?.plan.slug,
                },
                userProperties: {
                    email: values?.email,
                    ingress_year: values?.year,
                    semester: values?.semester,
                },
            });
            addsUserContext(values?.email);

            sendPersonalForm({
                name: values.name,
                email: values.email,
                year: values.year,
                semester: values.semester,
                telephone: values.telephone,
                ddi: values.ddi,
                cpf: values.cpf,
            });

            return verifyEmailNewPayment(values, infos)
                .then(async (data) => {
                    setFields((prev) => ({ ...prev, ...values }));
                    setUser(data.user);

                    identify({ user: data.user });

                    setEmail(values.email);

                    LocalStorageHelper.set('userId', data.user.id);

                    const isWinback =
                        (skipAccessCheck ||
                            data.user.subscription?.status === 'inactive') &&
                        data.user.subscription?.hasPreviousSubscription;

                    const { ingressYear, ingressSemester } = data.user.school;

                    const isBeginner =
                        !isWinback &&
                        isCurrentSemester(ingressSemester, ingressYear);

                    if (
                        !skipAccessCheck &&
                        data.user.subscription?.status === 'active'
                    ) {
                        return HAS_ACTIVE_SUBCRIPTION.code;
                    }

                    await updateOrder({ isWinback, isBeginner }).catch(() =>
                        push('/falha')
                    );

                    if (isWinback) {
                        return HAS_INACTIVE_SUBSCRIPTIONS.code;
                    }
                })
                .catch(onVerifyError);
        },
        [setErrorCode, infos, isWeb, query, order]
    );

    const onSendPayment = useCallback(
        async (paymentInfo: PaymentInfo, user?: User) => {
            onTrack('CONFIRM_PURCHASE');
            try {
                const data = await sendNewPayment(
                    paymentInfo,
                    infos,
                    fields,
                    user,
                    order,
                    query
                );

                setEcommerceCoupon(data?.coupon);

                onSendSuccess();

                sendPaymentForm({
                    plan: order.plan.slug,
                    email: user.email,
                    amount: order.plan.price,
                    userId: user.id,
                    coupon: data?.coupon?.code,
                    isWinback: order.isWinback,
                    createdAt: new Date(),
                    paymentMethod: paymentMethodMap(
                        order.payment.paymentMethod
                    ),
                });

                return data;
            } catch (err) {
                onSendError(err);
            }
        },
        [infos, fields, order]
    );

    useEffect(() => {
        setWidth(window.innerWidth);

        if (!order?.plan) return;
        sendMetric({
            eventName: 'Checkout page: viewed',
            eventProperties: {
                plan_slug: order.plan.slug,
            },
        });
    }, [order?.plan.slug]);

    const props: Props = useMemo(
        () => ({
            ...infos,
            errorCode,
            setErrorCode,
            fields,
            setFields,
            onVerifyEmail,
            onSendPayment,
            email,
            query,
        }),
        [
            infos,
            errorCode,
            setErrorCode,
            fields,
            setFields,
            onVerifyEmail,
            onSendPayment,
            query,
        ]
    );

    const page = useMemo(() => {
        return isWeb ? <Desktop {...props} /> : <Mobile {...props} />;
    }, [isWeb, props]);

    if (!width || !order || !flagsReady) {
        return <Loading />;
    }
    if (!order.plan) {
        return <InvalidPlan />;
    }

    return page;
}
